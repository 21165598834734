import { withFormsy } from 'formsy-react';
import React from 'react';
import "./formsy.css";

class FormsyInput extends React.Component {
    constructor(props) {
        super(props);
        this.changeValue = this.changeValue.bind(this);
        this.focusedValue = this.focusedValue.bind(this);
        this.bluredValue = this.bluredValue.bind(this);

        if (props.defaultValue) {
            props.setValue(props.defaultValue);
        }
        this.regExpVar = "";
        this.state = { isFocused: false };
    }

    changeValue(event) {
        // setValue() will set the value of the component, which in
        // turn will validate it and the rest of the form
        // Important: Don't skip this step. This pattern is required
        // for Formsy to work.
        this.props.setValue(event.currentTarget.value);
    }

    focusedValue(event) {
        this.setState({ isFocused: true })
    }

    bluredValue(event) {
        if( this.props.value ){
            this.setState({ isFocused: true })
        }else{
            this.setState({ isFocused: false })
        }
    }


    render() {
        // An error message is passed only if the component is invalid
        // CUSTOMIZED TO WORK WITH DYNAMIC REGEXP
        const errorMessage = this.regExpVar !== "" ? this.regExpVar : this.props.errorMessage;

        return (
            <div className={this.props.classes ? this.props.classes : ""}>
                {/* {this.props.label && (
                    <div className="text-white">{this.props.label}</div>
                )} */}

                {/* {this.state.isFocused && (
                    <div style={{fontSize: "20px",color: "#e2e8f0"}}>{this.props.placeholder}</div>
                )} */}

                <label className="text-white" style={{fontSize: "20px",color: "#031b4e"}} for={"formsy-" + this.props.name}>{this.props.placeholder}</label>

                <input
                    id={"formsy-" + this.props.name}
                    className="custom-input"
                    // placeholder={this.state.isFocused ? "" : this.props.placeholder}
                    onFocus={this.focusedValue}
                    onBlur={this.bluredValue}
                    onChange={this.changeValue}
                    type={this.props.type ? this.props.type : "text"}
                    value={this.props.value || ''}
                    ariaLabel={this.props.placeholder}
                />

                <span className="text-red-700 text-sm">{errorMessage}</span>
            </div>
        );
    }
}

export default withFormsy(FormsyInput);