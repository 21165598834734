import React, { useState } from 'react'
import "./Contact.css"

import Marker from "../../images/placeholder.svg";



function Contact(props) {
    const data = props.data;

    console.log(data)

    return (
        <div className="about-container">
            <div className='ab-pd-m'>
                <div className='m-auto container-cs text-left relative px-4 md:px-0'>
                    <div className='about-m-editor' style={{maxWidth: "600px"}} dangerouslySetInnerHTML={{ __html: data.content }} />
                    {/* <h1 className='contact-h1'>{data.title}</h1> */}
                </div>
            </div>
        </div>
    )
}

export default Contact;