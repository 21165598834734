import React, { useState, useRef, useEffect } from 'react'
import { GatsbyImage } from "gatsby-plugin-image";
import Formsy from 'formsy-react';
import axios from "axios"

import FormsyInput from "../Formsy/formsyInput"
import FormsyTextArea from "../Formsy/FormsyTextArea"

import "./ContactSection.css";


function ContactSection2(props) {
    const data = props;

    console.log(data)

    const formRef = useRef(null);


    const [canSubmit, setCanSubmit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const [emailFailed, setEmailFailed] = useState(false);
    const [emailResponse, setEmailResponse] = useState("");
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [showErrorCheckbox, setShowErrorCheckbox] = useState(false);
    const [hasGdpr, setHasGdpr] = useState(false)

    useEffect(() => {
        if (canSubmit) {
            setShowErrorMessage(false)
        }
    }, [canSubmit])


    function disableButton() {
        setCanSubmit(false);
    }

    function enableButton() {
        setCanSubmit(true);
    }

    function submit(model) {
        console.log("mesa")
        if (hasGdpr) {
            setLoading(true)

            let type = "";

            if (model.fullName) {
                let payload = {
                    ...model,
                    language: props.language,
                }

                axios.post(`https://admin.ficon.gr/wp-json/myplugin/v1/websiteContactEmail`, payload)
                    .then(function (response) {
                        // handle success
                        setEmailFailed(false)
                        setLoading(false)
                        setEmailSent(true)
                        setEmailResponse(response.data)

                    })
                    .catch(function (error) {
                        // handle error
                        setLoading(false)
                        setEmailFailed(true)
                        setEmailResponse(data.responses.failed)
                    })
            }
        } else {
            setLoading(false)
            setEmailFailed(true)
            setEmailResponse("Αποδεχτείτε τους Όρους χρήσης και την Πολιτική Προστασίας")
        }
    }


    return (
        <div className={`px-4 py-12 md:px-0`}>
            <div className={`m-auto mt-4 ${emailSent ? "max-w-none px-8 " : "max-w-xl px-4 "}relative`} >
                {loading && (
                    <div className="loader-container">
                        <div className="loader">Loading...</div>
                    </div>
                )}


                {!emailSent
                    ?
                    <Formsy
                        onValidSubmit={submit}
                        onValid={enableButton}
                        onInvalid={disableButton}
                        className={loading ? "opacity-25" : "opacity-100"}
                        ref={formRef}
                    >

                        <h2 className="text-4xl text-center">
                            Μίλησέ μας για την επιχείρησή σου
                        </h2>


                        <div className="mt-12">

                            <div className="flex flex-wrap">
                                <FormsyInput
                                    name="fullName"
                                    placeholder={'Ονοματεπώνυμο *'}
                                    classes="mt-4 w-full md:w-1/2 pr-0 md:pr-4"
                                    required
                                />
                                <FormsyInput
                                    name="email"
                                    validations={"isEmail"}
                                    validationErrors={{
                                        isEmail: "Το email που εισάγετε δεν είναι έγκυρο.",
                                    }}
                                    placeholder={'Email *'}
                                    classes="mt-4 w-full md:w-1/2 pl-0 md:pl-4"
                                    required
                                />
                            </div>
                            <div className="flex flex-wrap">
                                <FormsyInput
                                    name="telephone"
                                    validations={"minLength:10"}
                                    type="number"
                                    validationErrors={{
                                        minLength: "Ο αριθμός τηλεφώνου που βάλατε δεν είναι έγκυρος.",
                                    }}
                                    placeholder={`Αριθμός τηλεφώνου *`}
                                    classes="mt-4 w-full md:w-1/2 pr-0 md:pr-4"
                                    required={data.teleRequired ? true : false}
                                />

                                <FormsyInput
                                    name="subject"
                                    placeholder={"Θέμα *"}
                                    classes="mt-4 w-full md:w-1/2 pl-0 md:pl-4"
                                    defaultValue={data.subject}
                                    required
                                />
                            </div>


                            <FormsyTextArea
                                name="message"
                                validations={"minLength: 9"}
                                validationErrors={{
                                    minLength: "Το μήνυμά σας πρέπει να περιέχει τουλάχιστον 9 χαρακτήρες.",
                                }}
                                placeholder={"Μήνυμα *"}
                                classes="mt-4"
                                required
                            />

                        </div>

                        {props.language !== "EN"
                            ?
                            <div className='pb-2'>
                                <span>
                                    <input type="checkbox" checked={hasGdpr} onClick={() => setHasGdpr(!hasGdpr)} />
                                </span>
                                <span className='pl-2'>
                                    Αποδέχομαι την
                                    <a href={"https://ficons.gr/privacy-policy/"} target='_blank' className='pl-2' style={{ color: "#0056b3" }}>Πολιτική Προστασίας Προσωπικών Δεδομένων</a>
                                </span>
                            </div>
                            :
                            <div className='pb-2'>
                                <span>
                                    <input type="checkbox" checked={hasGdpr} onClick={() => setHasGdpr(!hasGdpr)} />
                                </span>
                                <span className='pl-2'>
                                    I accept the
                                    <a href={"https://ficons.gr/en/privacy-policy/"} target='_blank' className='pl-2' style={{ color: "#0056b3" }}>Privacy Policy</a>
                                </span>
                            </div>

                        }

                        {emailFailed && (
                            <p className='pb-4' style={{ color: "#b70000", fontSize: "1.2rem" }}>Αποτυχία αποστολής μηνύματος. Παρακαλούμε προσπαθήστε σε λίγο.</p>
                        )}

                        <div className='flex justify-center mt-8'>
                            <button type='submit' className='main-bttn' style={{ padding: "1rem 2rem" }}>
                                <span>Αποστολή</span>
                            </button>
                        </div>


                        {showErrorMessage && (
                            <p className="text-center mt-8" style={{ color: "#b70000", fontSize: "1.2rem", }}>Συμπληρώστε όλα τα απαραίτητα πεδία (*)</p>
                        )}
                        {showErrorCheckbox && (
                            <p className="text-center mt-8" style={{ color: "#b70000", fontSize: "1.2rem", }}>Παρακαλoύμε αποδεχτείτε τους όρους χρήσης</p>
                        )}
                    </Formsy>
                    :
                    <div >
                        <div className="email-response" dangerouslySetInnerHTML={{ __html: emailResponse }} />
                    </div>
                }

            </div>
        </div>
    )
}

export default ContactSection2;