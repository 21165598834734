import React, { useRef } from "react"
import { graphql, Link } from "gatsby"

import Layout from '../components/layout'

import Contact from "../components/Contact/Contact"

import ContactSection from "../components/Home/ContactSection"

import ContactSection2 from "../components/Contact/ContactSection2"

function ContactPageTemplate(props) {
    console.log(props)
    let data = props.data;

    // console.log(data)


    function constructMetaData(page, currentPage, language) {
        let payload = {
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://ficon.gr${currentPage}`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://ficon.gr${currentPage}`,
            fbType: "website",
            locale: language.toLowerCase(),
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
        }
        return payload
    }


    return (
        <Layout
            headerMenu={props.pageContext.headerMenu}
            footerMenu={props.pageContext.footerMenu}
            currentPage={{ currentPage: props.pageContext.currentPage, language: props.pageContext.languageName }}
            availableVersions={props.pageContext.availablePages}
            globalInfo={data.wpTemplate.globalInformation}
            mode="dark"
            locale={props.pageContext.language}
            metaData={constructMetaData(data.wpPage, props.pageContext.currentPage, props.pageContext.language)}
        // services={props.pageContext.servicesCategories}
        >
            <div>
                <section>
                    <Contact
                        data={data.wpPage}
                    />
                </section>
                {/* <section>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2639.110246830728!2d7.766367699999999!3d48.5885879!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4796c8f75b693f0b%3A0x3f2504e5aae44e4f!2s32%20All.%20de%20la%20Robertsau%2C%2067000%20Strasbourg%2C%20France!5e0!3m2!1sen!2sgr!4v1658760340057!5m2!1sen!2sgr" width="100%" height="640" style={{ border: "0" }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
                </section> */}

                <section>
                    <ContactSection2
                        data={data.wpTemplate.globalInformation?.contactForm}
                        dataTitle={data.wpPage.content}
                    />
                </section>

                <section>
                    <ContactSection
                        data={data.wpTemplate.globalInformation?.contactForm}
                    />
                </section>
            </div>
        </Layout>
    )
}

export default ContactPageTemplate

export const pageQuery = graphql`query GET_CONTACT_PAGE($id: String, $templateId: String) {
    wpPage(id: { eq: $id}){
        seo {
            canonical
            title
            metaDesc
            opengraphDescription
            opengraphTitle
            opengraphImage {
                sourceUrl
            }
        }
        title
        content
    }

    wpTemplate(slug: {eq: $templateId}) {
		globalInformation {
            contactInfo {
                phone {
                    label
                    text
                }
                address {
                    label
                    text
                }
                email {
                    label
                    text
                }
            }
            contactForm{
                title
                content
                socialLinks{
                    social{
                        type
                        link
                    }
                }
                form{
                    label
                    type
                    errorMessage
                    value
                }
                formCta
            }
            footerExtra {
                description
                copyright
            }
        }
    }
}
`
